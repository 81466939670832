.hero {
  background-color: #FDFBF7;
  height: calc(100vh - 54px);
  padding: 5rem 9rem;
}

.hero-page {
  display: flex;
  justify-content: center;
  align-items: top;
}

.hero-btn{
  background-color: #D8AE5F;
  color: white;
}

.hero-content {
  width: 60%;
  /* border: solid 1px green; */
}
.hero-content > h1 {
  font-size: 4rem;
  margin: 0px;
  font-weight: 700;
  padding-bottom: 1.5rem;
}
.hero-content > p {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.hero-image {
  width: 40%;
}

.hero-image > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media all and (max-width: 480px) {
  .hero {
    padding: 1rem 1rem;
    height: fit-content;
  }
  .hero-page {
    flex-direction: column;
  }

  .hero-content {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }

  .hero-content > h1 {
    padding-top: 4rem;
    padding-bottom: 1.5rem;
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }

  .hero-content > p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }

  .hero-image {
    width: 100%;
    height: 38vh;
  }
}
